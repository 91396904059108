import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./transfer.module.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [val, setval] = useState([
    { name: "chk01", value: "" },
    { name: "chk02", value: "" },
    { name: "chk03", value: "" },
  ])

  const handleFrequencyChange = e => {
    if (val.length === 0) {
      setval([...val, { name: e.target.name, value: e.target.value }])
    } else {
      const found = val.find(element => element.name === e.target.name)
      // console.log(found)
      if (found === undefined) {
        setval([...val, { name: e.target.name, value: e.target.value }])
      } else {
        const newValue02 =
          val.find(item => item.name === e.target.name).value === e.target.value
            ? ""
            : e.target.value
        let newval = val.filter(item => item.name !== e.target.name)
        newval.push({ name: e.target.name, value: newValue02 })
        setval(newval)
      }
    }
    // console.log(val)
  }
  // ytag.jsをheadタグに埋め込み
  if (typeof document !== `undefined`) {
    const script1 = document.createElement('script')
    script1.src = `https://s.yimg.jp/images/listing/tool/cv/ytag.js`
    script1.async = true
    document.head.appendChild(script1)
    
    // 実行用scriptをheadタグに埋め込み
    const script2 = document.createElement('script')
    script2.text = `window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});`
    document.head.appendChild(script2)
  }


  // Google広告コンバージョン用関数
  const ConversionLanHD264E = () => {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/gqjwCK2op-8CEO7_9ZwC",
      })
    const scriptLanHD264E = document.createElement('script')
    scriptLanHD264E.text = `
    ytag({
      "type": "yss_conversion",
      "config": {
        "yahoo_conversion_id": "1001162645",
        "yahoo_conversion_label": "7A7ZCMTM9vwCEIqA-pQC",
        "yahoo_conversion_value": "100"
      }
    });`;
    document.body.appendChild(scriptLanHD264E)
  }

  const ConversionLanHD264D = () => {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/izfSCOiw4_ICEO7_9ZwC",
      })
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptLanHD264D = document.createElement('script')
        scriptLanHD264D.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "_1hOCMO1zfwCEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptLanHD264D)
    }
  }

  const ConversionLanHD264ED = () => {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/HPlpCOi24_ICEO7_9ZwC",
      })
    if (typeof document !== `undefined`) {
    // 実行用scriptをbodyタグに埋め込み
        const scriptLanHD264ED = document.createElement('script')
        scriptLanHD264ED.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "0zxaCNrU9_wCEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptLanHD264ED)
    }
  }

  const ConversionContact = () => {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/R1-5CPv24vICEO7_9ZwC",
      })
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "AbO8CKbCzfwCEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="映像伝送装置"
        description="HD-SDI、HDMI映像をIP化、遠隔監視や遠隔モニタリングの実装に"
      />
      <div className={styles.header}>
        <p className={styles.headerButton}>
          <span>HD-SDI対応</span>
        </p>
        <h1>
          映像伝送装置<span>&nbsp;LAN-HDシリーズ</span>
        </h1>
        <p>HD-SDI、HDMI映像をIP化、遠隔監視や遠隔モニタリングの実装に</p>
        <Link
          to="/contact/?subject=画像伝送装置に関するお問い合わせ"
          onClick={ConversionContact}
        >
          <button>設置の相談をする</button>
        </Link>
        <StaticImage
          className={styles.headerImage01}
          src="./images/transfer/header-lan-hd264e.png"
          width={200}
        />
        <StaticImage
          className={styles.headerImage02}
          src="./images/transfer/header-lan-hd264ed.png"
          width={220}
        />
      </div>
      <div className={styles.bodyOne}>
        <StaticImage
          className={styles.bodyOneImageManLeft}
          src="./images/transfer/man-left.png"
          height={250}
        />
        <StaticImage
          className={styles.bodyOneImageManRight}
          src="./images/transfer/man-right.png"
          width={150}
          height={270}
        />
        <h1>
          <StaticImage
            className={styles.bodyOneImageLeft}
            src="./images/transfer/blue-line-left.svg"
            width={30}
          />
          こんなことで
          <br className={styles.bodyOneHidden} />
          お困りではありませんか？
          <StaticImage
            className={styles.bodyOneImageRight}
            src="./images/transfer/blue-line-right.svg"
            width={30}
          />
        </h1>
        <div className={styles.bodyOneRight1}>
          <p>
            HD-SDIカメラ、
            <br className={styles.bodyOneHidden} />
            アナログカメラの映像を
            <br />
            <span>
              インターネット経由で
              <br className={styles.bodyOneHidden} />
              遠隔地に伝送
            </span>
            できないかな…
          </p>
        </div>
        <div className={styles.bodyOneLeft1}>
          <p>
            <span>工場のモニタ映像</span>を
            <br />
            別の場所にある工場
            <br className={styles.bodyOneHidden} />
            などでも表示したい..
          </p>
        </div>
        <div className={styles.bodyOneRight2}>
          <p>
            ネット接続できない
            <br className={styles.bodyOneHidden} />
            パソコンの
            <span>
              モニタ映像
              <br />
              だけを遠隔へ伝送する
            </span>
            <br className={styles.bodyOneHidden} />
            方法を知りたい…
          </p>
        </div>
        <div className={styles.bodyOneLeft2}>
          <p>
            IPカメラの映像を
            <br className={styles.bodyOneHidden} />
            <span>
              PCを使わずに
              <br />
              モニタに表示したい
            </span>
            <br className={styles.bodyOneHidden} />
            けどできない…
          </p>
        </div>
      </div>
      <div className={styles.bodyTwo}>
        <div className={styles.bodyTwoCenter}>
          <StaticImage
            className={styles.bodyTwoImageArrow}
            src="./images/transfer/blue-arrow.svg"
          />
        </div>
        <StaticImage
          className={styles.bodyTwoImageWoman}
          src="./images/transfer/woman-point.png"
          width={250}
        />
        <h1>
          LAN-HDシリーズで
          <br />
          <span>すべて解決できます！</span>
        </h1>
      </div>
      <div className={styles.bodyThree}>
        <h1>
          映像伝送装置&nbsp;
          <br className={styles.bodyOneHidden} />
          LAN-HDシリーズでできること
        </h1>
        <hr />
        <div className={styles.bodyThreeGridWrapper}>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage
                className={styles.bodyThreeImageProduct}
                src="./images/transfer/items-lan-hd264e.png"
              />
              <a
                href="../../page/transfer/transfer-lan-hd264e"
                onClick={ConversionLanHD264E}
              >
                <button>LAN-HD264E</button>
              </a>
              <p>エンコード専用機</p>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>
                HD-SDI、アナログ、HDMI出力の映像をデジタル信号へ変換。
                <br />
                インターネット経由で遠隔地へ伝送可能。
                <br />
                映像以外にも音声や接点信号、RS-485 信号を一緒に伝送も可能。
                <br />
                LAN-HD264Dと組み合わせてモニタへ映像をうつす事も可能。
              </p>
            </div>
          </div>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage
                className={styles.bodyThreeImageProduct}
                src="./images/transfer/items-lan-hd264d.png"
              />
              <a
                href="../../page/transfer/transfer-lan-hd264d"
                onClick={ConversionLanHD264D}
              >
                <button>LAN-HD264D</button>
              </a>
              <p>デコード専用機</p>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>
                IPカメラの映像やLAN-HD264Eで変換したデジタル信号をデコードし、HDMIやHD-SDI、アナログ映像出力として出力が可能。
                <br />
                最大4台のIPカメラの映像をデコードし、HDMI、HD-SDI、アナログの各映像出力から別々の映像出力も可能。
              </p>
            </div>
          </div>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage
                className={styles.bodyThreeImageProduct}
                src="./images/transfer/items-lan-hd264ed.png"
              />
              <a
                href="../../page/transfer/transfer-lan-hd264ed"
                onClick={ConversionLanHD264ED}
              >
                <button>LAN-HD264ED</button>
              </a>
              <p>エンコード・デコード両用機</p>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>
                エンコード機能とデコード機能を切り替えて使用が可能です。
                <br />
                映像をエンコードして伝送したいときはエンコーダ機として、デコードしてモニタに出力したいときはデコーダ機として使用可能。
              </p>
              <p className={styles.bodyThreeGridProductRightNote}>
                <StaticImage src="./images/transfer/items-note.svg" />
                &nbsp;1台でエンコード機能とデコード機能を同時に使用は出来ません。同時にする場合は2台必要となります
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bodyFour}>
        <div className={styles.bodyFourLeft}>
          <StaticImage src="./images/transfer/outside-long.jpg" height={280} />
        </div>
        <div className={styles.bodyFourRight}>
          <h1>導入実績</h1>
          <hr />
          <p>大手放送局、大手企業、病院など…</p>
          <h2>
            <StaticImage
              src="./images/transfer/red-line-left.svg"
              height={20}
            />
            実績多数
            <StaticImage
              src="./images/transfer/red-line-right.svg"
              height={20}
            />
          </h2>
          <Link
            to="/contact/?subject=画像伝送装置に関するお問い合わせ"
            onClick={ConversionContact}
          >
            <button>設置の相談をする</button>
          </Link>
        </div>
      </div>
      <div className={styles.bodyFive}>
        <h1>設置例</h1>
        <hr />
        <div className={styles.bodyFiveWrap}>
          <div className={styles.bodyFiveFlex}>
            <div className={styles.bodyFiveHeaderLeft}>
              <p>
                <StaticImage src="./images/transfer/items-lan-hd264e.png" />
              </p>
            </div>
            <div className={styles.bodyFiveHeaderRight}>
              <p className={styles.bodyFiveHeaderP}>
                <a
                  href="../../page/transfer/transfer-lan-hd264e"
                  onClick={ConversionLanHD264E}
                >
                  <button>LAN-HD264E</button>
                </a>
                <br className={styles.bodyOneHidden} />
                <span className={styles.bodyFiveHeaderSpan}>
                  HD-SDI/HDMI映像を遠隔地へ伝送
                </span>
              </p>
              <p>
                <span>
                  HD-SDIやHDMIの映像をLANに変換しインターネット経由で遠隔地へ伝送
                </span>
                が可能です。
                <br />
                遠隔地ではPCやスマホ＊など複数同時に閲覧が可能です。
                <br />
                またLAN-HD264Dと組み合わせる事でモニタに表示させる事もできます。
              </p>
              <p>
                ※スマホの場合、RTSPやonvif接続可能なアプリのインストールが必要です
              </p>
            </div>
          </div>
          <StaticImage src="./images/transfer/description-lan-hd264e-001.jpg" />
          <StaticImage src="./images/transfer/description-lan-hd264e-002.jpg" />
        </div>
        <div className={styles.bodyFiveWrap}>
          <div className={styles.bodyFiveFlex}>
            <div className={styles.bodyFiveHeaderLeft}>
              <p>
                <StaticImage src="./images/transfer/items-lan-hd264d.png" />
              </p>
            </div>
            <div className={styles.bodyFiveHeaderRight}>
              <p className={styles.bodyFiveHeaderP}>
                <a
                  href="../../page/transfer/transfer-lan-hd264d"
                  onClick={ConversionLanHD264D}
                >
                  <button>LAN-HD264D</button>
                </a>
                <br className={styles.bodyOneHidden} />
                <span className={styles.bodyFiveHeaderSpan}>
                  IPカメラの映像をモニタへ出力
                </span>
              </p>
              <p>
                LAN-HD264DはIPカメラの映像やLAN-HD264Eで変換したデジタル信号をデコードし、HDMIやHD-SDI、アナログ映像出力として出力が可能です。
                <br />
                最大4台のIPカメラと接続可能で１画面、２分割、４分割画面で映像を表示させたり、No.1～No.4までのIPカメラの映像を一定時間事に順送りで表示する事が可能です。
              </p>
            </div>
          </div>
          <div className={styles.bodyFiveFlex}>
            <div className={styles.bodyFiveHeaderLeft2}>
              <StaticImage src="./images/transfer/description-lan-hd264d-001.jpg" />
            </div>
            <div className={styles.bodyFiveHeaderRight2}>
              <p>
                映像出力端子にHDMI、HD-SDI、アナログの３種類の端子がついており、各映像出力端子から別々の映像を出力する事も可能
                <br />
                【HDMI出力】
                <br />
                No.2とNo,3のIPカメラ映像を分割表示
                <br />
                <br />
                【HD-SDI出力】
                <br />
                No.1～No.4までのIPカメラ映像を一定時間事に切り替え表示
                <br />
                <br />
                【アナログ出力】
                <br />
                No.1のIPカメラ映像を表示
              </p>
            </div>
          </div>
        </div>
        <div className={styles.bodyFiveFlex}>
          <div className={styles.bodyFiveHeaderLeft3}>
            <StaticImage src="./images/transfer/items-lan-hd264e-and-d.jpg" />
          </div>
          <div className={styles.bodyFiveHeaderRight3}>
            <a
              href="../../page/transfer/transfer-lan-hd264e"
              onClick={ConversionLanHD264E}
            >
              <button>LAN-HD264E</button>
            </a>
            <a
              href="../../page/transfer/transfer-lan-hd264d"
              onClick={ConversionLanHD264D}
            >
              <button>LAN-HD264D</button>
            </a>
            <h1>シリアル、制御信号の遠方伝送</h1>
          </div>
        </div>
        <p>
          LAN-HD264Eは
          <span>
            映像以外にもシリアル信号（RS232C,RS485)と接点信号を同時に伝送
          </span>
          できます。
          <br />
          LAN-HD264EとLAN-HD264Dを組み合わせて工場やプラント設備でカメラ映像と一緒にPLCなどの制御機器の
          信号やセンサー接点信号を遠隔へ伝送し、映像と一緒にモニタリング環境を構築する事も可能です。
        </p>
        <StaticImage src="./images/transfer/description-lan-hd264e-003.jpg" />
      </div>
      <div className={styles.bodySix}>
        <h1>
          用途に合わせて
          <br className={styles.bodySixHidden} />
          最適な装置をお選びします
        </h1>
        <StaticImage
          className={styles.bodySixImageWoman}
          src="./images/transfer/woman-pen.png"
        />
      </div>
      <div className={styles.bodySeven}>
        <div className={styles.bodySevenHeader}>
          <h1>簡単なアンケートにお答えください。</h1>
        </div>
        <div className={styles.bodySevenMenuWrap}>
          <p>Q.映像を遠隔地へ伝送したいですか？</p>
          <div className={styles.bodySevenMenu01}>
            <input
              type="checkbox"
              id="menu01-y"
              name="chk01"
              value="menu01-y"
              checked={
                val.find(item => item.name === "chk01").value === "menu01-y"
              }
              onChange={handleFrequencyChange}
            />
            <label htmlFor="menu01-y">はい</label>
            <input
              type="checkbox"
              id="menu01-n"
              name="chk01"
              value="menu01-n"
              checked={
                val.find(item => item.name === "chk01").value === "menu01-n"
              }
              onChange={handleFrequencyChange}
            />
            <label htmlFor="menu01-n">いいえ</label>
          </div>
        </div>
        {val.find(item => item.name === "chk01").value === "menu01-y" && (
          <div className={styles.bodySevenMenuWrap}>
            <p>Q.伝送した映像をPC無しでモニタに表示したいですか？</p>
            <div className={styles.bodySevenMenu01}>
              <input
                type="checkbox"
                id="menu02-y"
                name="chk02"
                value="menu02-y"
                checked={
                  val.find(item => item.name === "chk02").value === "menu02-y"
                }
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu02-y">はい</label>
              <input
                type="checkbox"
                id="menu02-n"
                name="chk02"
                value="menu02-n"
                checked={
                  val.find(item => item.name === "chk02").value === "menu02-n"
                }
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu02-n">いいえ</label>
            </div>
          </div>
        )}
        {val.find(item => item.name === "chk01").value === "menu01-n" && (
          <div className={styles.bodySevenMenuWrap}>
            <p>Q.IPカメラの映像をモニタに表示させたいですか？</p>
            <div className={styles.bodySevenMenu01}>
              <input
                type="checkbox"
                id="menu03-y"
                name="chk03"
                value="menu03-y"
                checked={
                  val.find(item => item.name === "chk03").value === "menu03-y"
                }
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu03-y">はい</label>
              <input
                type="checkbox"
                id="menu03-n"
                name="chk03"
                value="menu03-n"
                checked={
                  val.find(item => item.name === "chk03").value === "menu03-n"
                }
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu03-n">いいえ</label>
            </div>
          </div>
        )}
        <div className={styles.bodySevenProduct}>
          {/* <p>選択値：{val.join(', ')}</p> */}
          {val.find(item => item.name === "chk01").value === "menu01-y" &&
            val.find(item => item.name === "chk02").value === "menu02-y" && (
              <>
                <h3>最適な商品はこちら</h3>
                <StaticImage
                  className={styles.bodyThreeImageProduct}
                  src="./images/transfer/items-lan-hd264e.png"
                />
                <p>
                  <a
                    href="../../page/transfer/transfer-lan-hd264e"
                    onClick={ConversionLanHD264E}
                  >
                    <button>LAN-HD264E</button>
                  </a>
                </p>
                <StaticImage
                  className={styles.bodyThreeImageProduct}
                  src="./images/transfer/items-lan-hd264d.png"
                />
                <p>
                  <a
                    href="../../page/transfer/transfer-lan-hd264d"
                    onClick={ConversionLanHD264D}
                  >
                    <button>LAN-HD264D</button>
                  </a>
                </p>
              </>
            )}
          {val.find(item => item.name === "chk01").value === "menu01-y" &&
            val.find(item => item.name === "chk02").value === "menu02-n" && (
              <>
                <h3>最適な商品はこちら</h3>
                <StaticImage
                  className={styles.bodyThreeImageProduct}
                  src="./images/transfer/items-lan-hd264e.png"
                />
                <p>
                  <a
                    href="../../page/transfer/transfer-lan-hd264e"
                    onClick={ConversionLanHD264E}
                  >
                    <button>LAN-HD264E</button>
                  </a>
                </p>
              </>
            )}
          {val.find(item => item.name === "chk01").value === "menu01-n" &&
            val.find(item => item.name === "chk03").value === "menu03-y" && (
              <>
                <h3>最適な商品はこちら</h3>
                <StaticImage
                  className={styles.bodyThreeImageProduct}
                  src="./images/transfer/items-lan-hd264d.png"
                />
                <p>
                  <a
                    href="../../page/transfer/transfer-lan-hd264d"
                    onClick={ConversionLanHD264D}
                  >
                    <button>LAN-HD264D</button>
                  </a>
                </p>
                <StaticImage
                  className={styles.bodyThreeImageProduct}
                  src="./images/transfer/items-lan-hd264ed.png"
                />
                <p>
                  <a
                    href="../../page/transfer/transfer-lan-hd264ed"
                    onClick={ConversionLanHD264ED}
                  >
                    <button>LAN-HD264ED</button>
                  </a>
                </p>
              </>
            )}
          {val.find(item => item.name === "chk01").value === "menu01-n" &&
            val.find(item => item.name === "chk03").value === "menu03-n" && (
              <>
                <p>該当の製品はございません。</p>
              </>
            )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
