// extracted by mini-css-extract-plugin
export var header = "transfer-module--header--tPBtm";
export var headerButton = "transfer-module--headerButton--BV4B5";
export var headerImage01 = "transfer-module--headerImage01--jdA1J";
export var headerImage02 = "transfer-module--headerImage02--D84C3";
export var headerImage03 = "transfer-module--headerImage03--9PVht";
export var bodyOne = "transfer-module--bodyOne--40cgg";
export var bodyOneImageLeft = "transfer-module--bodyOneImageLeft--lgZcV";
export var bodyOneImageRight = "transfer-module--bodyOneImageRight--P4Nyc";
export var bodyOneImageManLeft = "transfer-module--bodyOneImageManLeft--Zqnvb";
export var bodyOneImageManRight = "transfer-module--bodyOneImageManRight--mwDBF";
export var bodyOneRight1 = "transfer-module--bodyOneRight1--kFFuR";
export var bodyOneLeft1 = "transfer-module--bodyOneLeft1--6o+vv";
export var bodyOneRight2 = "transfer-module--bodyOneRight2--opnM0";
export var bodyOneLeft2 = "transfer-module--bodyOneLeft2--54jDH";
export var bodyOneHidden = "transfer-module--bodyOneHidden--PNd9f";
export var bodyOneMHidden = "transfer-module--bodyOneMHidden--zrLUS";
export var bodyTwo = "transfer-module--bodyTwo--MY75+";
export var bodyTwoCenter = "transfer-module--bodyTwoCenter--cO5TY";
export var bodyTwoImageArrow = "transfer-module--bodyTwoImageArrow--jXrEe";
export var bodyTwoImageWoman = "transfer-module--bodyTwoImageWoman--hv0F7";
export var bodyThree = "transfer-module--bodyThree--d8Ezs";
export var bodyThreeGridWrapper = "transfer-module--bodyThreeGridWrapper--hXDwM";
export var bodyThreeGridProduct = "transfer-module--bodyThreeGridProduct--Fqlk5";
export var bodyThreeImageProduct = "transfer-module--bodyThreeImageProduct--wct8x";
export var bodyThreeGridProductRightNote = "transfer-module--bodyThreeGridProductRightNote--2CucV";
export var bodyThreeGridProductLeft = "transfer-module--bodyThreeGridProductLeft--w2zRr";
export var bodyThreeGridProductRight = "transfer-module--bodyThreeGridProductRight--aw6h6";
export var bodyFour = "transfer-module--bodyFour--ldjQw";
export var bodyFourLeft = "transfer-module--bodyFourLeft--Qp73J";
export var bodyFourRight = "transfer-module--bodyFourRight--3+RG3";
export var bodyFive = "transfer-module--bodyFive--OALOv";
export var bodyFiveWrap = "transfer-module--bodyFiveWrap--Si376";
export var bodyFiveFlex = "transfer-module--bodyFiveFlex---5ipa";
export var bodyFiveHeaderLeft = "transfer-module--bodyFiveHeaderLeft--tTsT+";
export var bodyFiveHeaderRight = "transfer-module--bodyFiveHeaderRight--tNLRX";
export var bodyFiveHeaderSpan = "transfer-module--bodyFiveHeaderSpan--nJowb";
export var bodyFiveHeaderLeft2 = "transfer-module--bodyFiveHeaderLeft2--LYZgW";
export var bodyFiveHeaderRight2 = "transfer-module--bodyFiveHeaderRight2--ar5f2";
export var bodyFiveHeaderLeft3 = "transfer-module--bodyFiveHeaderLeft3--SwZkv";
export var bodyFiveHeaderRight3 = "transfer-module--bodyFiveHeaderRight3---MTox";
export var bodyFiveHeaderP = "transfer-module--bodyFiveHeaderP--tekY-";
export var bodySix = "transfer-module--bodySix--AJaIf";
export var bodySixImageWoman = "transfer-module--bodySixImageWoman--PEzSW";
export var bodySixHidden = "transfer-module--bodySixHidden--20NYt";
export var bodySeven = "transfer-module--bodySeven--xq2+m";
export var bodySevenHeader = "transfer-module--bodySevenHeader--i+sbu";
export var bodySevenMenu01 = "transfer-module--bodySevenMenu01--HhqFQ";
export var bodySevenMenuWrap = "transfer-module--bodySevenMenuWrap--5oNZw";
export var bodySevenProduct = "transfer-module--bodySevenProduct--Biuqy";